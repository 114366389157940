.tree-group-toolbar {
  //display: flex;
  //flex-wrap: wrap;
  //margin-bottom: 15px;
  //margin-top: 15px;
  //
  //& > * {
  //  margin-right: 6px;
  //  margin-bottom: 6px;
  //}

  .selector {
    //width: 280px;
  }

  .search {
    //width: auto;
  }

}

.tree-group-toolbar__input {
  min-width: 80px;
  & input {
    min-width: 80px;
    width: 100%;
  }
}

