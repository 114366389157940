//th, td {
//  border: 1px solid #dee2e6;
//}

.header-with-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;

  .u-toolbar {
    font-size: var(--size-text-1);
    font-weight: var(--fw-normal-400);

    margin-left: 3em;
    white-space: normal;

  }

  .selector {
    //flex: 1;
    min-width: 0;
  }

  .make-main__small {
    display: none;
  }

  @media screen and (max-width: 1600px) {
    .make-main {
      display: none;
    }

    .make-main__small {
      display: flex;
    }
  }

}