.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.delete-product-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.header {
  font-size: 18px;
  margin-bottom: 10px;
  padding-left: 20px;
}

.buttons-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.buttons-wrapper button {
  margin-left: 10px;
}