//.cat-category-group-list__wrapper {
//  list-style: none;
//  padding: 0;
//  font-size: 13px;
//  border-bottom: 1px solid #ccc;
//  overflow: auto;
//  //scrollbar-gutter: stable;
//  margin: 0;
//}

.cat-category-group-list__element {
  //padding: 4px 0;
  //border: 1px solid #ccc;
  //border-bottom: none;

  &.--selected {
    background: #007bff !important;
    color: white;
    //border-color: #007bff;
  }

  &.--highlighted {
    background-color: rgb(146, 244, 66);;
  }
}

.span-indent-wrapper {
  display: flex;
}

.li-indent {
  width: 20px;
  display: block;
  flex-shrink: 0;
}

.cat-category-group-list {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 22px;
  position: relative;

  .cat-category-group-list__name {
    width: 100%;
    flex: 1 1 0;
    height: 100%
  }

  .fa {
    padding: 4px;
    position: absolute;
    left: 0;
  }
}

.my-custom-checkbox__wrapper {
  margin: 2px 10px 2px 2px;
}