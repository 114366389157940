.images-grid-wrapper-7 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.625em 1.25em;

  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
  }
}