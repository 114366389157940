.menubar-wrapper {
  display: flex;
  flex-direction: column;
  width: min(18%, 330px);
  background-color: var(--color-neutral-0);
  border: 1px solid var(--color-neutral-200);
  border-radius: 0 1em 1em 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
  min-width: fit-content;

  padding: 3.25em 1.25em 1.25em;

  &.--small {
    width: auto;

    .logo-wrapper {
      display: flex;
      justify-content: center;
    }

    .logo-text,
    .support-button {
      display: none;
    }

    .link-icon,
    .logo-pic {
      margin: 0;
    }

    .u-button {
      position: relative;

      .link-name {
        display: none;
      }

      &:hover {
        z-index: 1;

        .link-name {
          display: block;
          position: absolute;
          left: calc(100% + (1.25 * var(--size-text-1) + 1em) );
          font-size: var(--size-text-2);
          color: var(--color-primary-100);
          background: var(--color-primary-700);
          padding: 0.5em 1.2em;
          border-radius: 0.4em;

          &::before {
            display: block;
            content: "◀";
            position: absolute;
            left: -0.7em;
            color: var(--color-primary-700);
          }
        }


      }
    }
  }

  .menubar {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    width: 100%;
    margin-inline: auto;
    flex: 1;
    //padding: 0.5rem;
  }

  .logo-wrapper {
    display: flex;
    margin-bottom: 2em;
  }

  .logo-pic {
    width: 2.5625em;
    height: 2.5625em;
    margin-right: 0.625em;
  }

  .logo-text {
    width: 5.875em;
    height: auto;
  }

  .menubar-search {
    margin-bottom: 1.25em;
  }

  .link-section {
    display: flex;
    flex-direction: column;
    padding-bottom: 2.5em;
    margin: 0;
    padding-left: 0;
    list-style: none;

    .u-button {
      margin-bottom: 0.625em;
    }
  }

  .link-icon {
    margin-right: 0.5em;
  }

  .link-name {
    margin-right: auto;
  }

  .button-section {
    border-top: 1px solid var(--color-neutral-200);
    padding-top: 1.25em;

  }





  .navbar-select-container {
    margin-left: 10px;
    font-size: 13px;
  }

  .navbar-logout-button {
    margin-inline: 10px;
  }

  .nav-button-new-card {
    cursor: pointer;
    background-color: chocolate;
    border-radius: 4px;
    font-weight: bold;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;

    &:hover {
      color: white;
      text-decoration: none;
    }
  }

  .support-link {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}