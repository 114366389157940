$font-path: "../../assets/fonts" !default;
$img-pdf-path: "../../assets/pdf" !default;

@font-face {
    font-family: 'PF Din Text Cond Pro';
    src: url('#{$font-path}/PFDinTextCondPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PF Din Text Cond Pro';
    src: url('#{$font-path}/PFDinTextCondPro Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
}

.render-pdf {
    overflow: auto;

    &-header {

        &-row1,
        &-row2 {
            display: grid;
            grid-column-gap: 12px;
            grid-row-gap: 16px;
            margin-bottom: 20px;
            align-items: center;
        }

        &-row1 {
            grid-template-columns: auto auto 1fr auto 75px 200px 1.5fr;
        }

        &-row2 {
            grid-template-columns: 0.5fr 0.75fr 0.5fr 1fr;
        }
    }

    .errors:not(:empty) {
        background-color: var(--color-danger-100);
        border-color: var(--color-danger-700);
        color: var(--color-danger-700);
        font-size: var(--size-text-2);
        border-radius: 0.75em;
        padding: 20px;
        margin-bottom: 20px;
    }

    .book {

        $page-outsider: 19px;
        $page-width: 801px;
        $page-body: 723px;
        $page-heigth: 1133px;
        $page-body-indent: 77px;
        $page-category-top: 135px;
        $page-category-bottom: 97px;
        $page-category-indent: 5px;

        font-family: 'PF Din Text Cond Pro', sans-serif;
        color: #000000;

        &.print {
            .page {
                width: $page-width + 2*$page-outsider;
                height: $page-heigth + 2*$page-outsider;
                padding-top: $page-outsider;
                padding-bottom: $page-outsider;

                .body {
                    width: $page-body + $page-outsider;
                }

                .category {
                    top: $page-category-top + $page-outsider;
                    bottom: $page-category-bottom + $page-outsider;
                }

                &.left {
                    padding-right: $page-outsider;

                    .body {
                        .header {
                            padding-left: $page-body-indent + $page-outsider;
                        }

                        .content {
                            left: $page-body-indent + $page-outsider;
                        }

                        .footer {
                            left: $page-body-indent + $page-outsider;
                        }

                        .category {
                            left: 12px;

                            img {
                                transform: scale(-1, 1);
                            }
                        }
                    }
                }

                &.right {
                    padding-left: $page-outsider;

                    .body {
                        .header {
                            padding-right: $page-body-indent + $page-outsider;
                        }

                        .content {
                            right: $page-body-indent + $page-outsider;
                        }

                        .footer {
                            right: $page-body-indent + $page-outsider;
                        }

                        .category {
                            right: 12px;

                            img {
                                transform: scale(1, 1);
                            }
                        }
                    }
                }
            }
        }

        .page {
            background: white;
            width: $page-width;
            height: $page-heigth;
            margin-left: auto;
            margin-right: auto;
            overflow: hidden;

            .body {
                width: $page-body;
                height: 100%;
                position: relative;

                .substrate {
                    width: 100%;
                    height: 974px;
                }

                .header {
                    padding-top: 16px;
                    padding-bottom: 10px;
                    display: grid;
                    grid-column-gap: 10px;
                    align-items: center;
                    font-weight: bold;
                    font-size: 26px;
                    line-height: 24px;
                    height: 98px;

                    .logo {
                        display: flex;
                        align-items: center;
                    }
                }

                .content {
                    height: 956px;
                    margin-top: 12px;
                    overflow: hidden;
                    position: absolute;
                    width: 647px;

                    &-base-image {
                        width: 100%;
                    }

                    &-info {
                        display: grid;
                        grid-template-columns: 233px 54px auto;
                        grid-column-gap: 12px;
                    }

                    &-toolset {
                        .title {
                            font-size: 12px;
                            font-weight: 500;
                        }

                        .table {
                            font-size: 11px;
                            line-height: 11px;
                            background: transparent;
                            width: 100%;
                            display: grid;
                            grid-template-columns: 34px 36px 230px 1fr;

                            div {
                                padding: 2px 6px;
                                border-top: 1px solid #C6C6C6;
                                align-items: center;
                                display: flex;

                                img {
                                    height: 12px;
                                    width: 32px;
                                }

                                &:nth-child(4n + 2) {
                                    font-weight: 600;
                                }
                            }
                        }
                    }

                    &-row {
                        &-image {
                            display: flex;
                            align-items: center;
                            margin-bottom: 8px;
                        }

                        &.toolset {
                            display: grid;
                            grid-template-columns: 1fr;
                            grid-template-rows: minmax(auto, 68%) auto auto;
                            justify-content: center;
                            height: 100%;

                            &.is-series {
                                grid-template-rows: auto minmax(auto, calc(67% - 10px)) auto auto
                            }

                            .content-base-image {
                                max-height: 100%;
                                max-width: 100%;
                                display: block;
                                margin: auto;
                                width: auto;
                                align-self: center;
                            }
                        }

                        &+div {
                            margin-top: 20px;
                        }
                    }

                    &-images {

                        img.anger,
                        img.plan {
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                            max-height: 220px;
                            max-width: 100%;
                        }

                        img.anger+img.plan,
                        img.anger+.pins,
                        img.plan+.pins {
                            margin-top: 8px
                        }

                        .pins {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 4px;

                            img {
                                width: 31px;
                                height: 31px;
                            }
                        }
                    }

                    &-qr {
                        display: flex;
                        flex-direction: column;

                        .qr {
                            width: 100% !important;
                            height: auto !important;
                        }

                        img {
                            max-width: 100%;
                        }

                        .box,
                        .pack {
                            max-height: 72px;
                            height: auto;
                            margin-top: 12px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }

                    &-body {
                        .title {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 19.2px;
                            padding-left: 2px;
                        }

                        .description {
                            padding: 6px;
                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px;
                            border: #C6C6C6 solid 1px;
                            border-right: none !important;
                            padding-right: 0 !important;
                            margin-top: 4px;

                            ul {
                                border-top-left-radius: 4px;
                                border-bottom-left-radius: 4px;
                                border: #C6C6C6 solid 1px;
                                border-right: none !important;
                                padding-left: 18px;
                                padding-top: 3px;
                                padding-bottom: 3px;
                                margin: 0;
                                font-size: 12px;
                                line-height: 12px;
                            }
                        }

                        .products {
                            font-size: 12px;
                            line-height: 12px;
                            background: transparent;
                            width: 100%;
                            text-align: center;
                            margin-top: -6px;

                            .thead {
                                color: #ED6E2C;
                                font-weight: 600;
                                font-size: 10px;
                                vertical-align: top;
                                width: 80%;
                                margin-left: auto;
                                margin-right: auto;
                                margin-bottom: 2px;
                                padding-left: 3px;
                                padding-right: 3px;
                                justify-content: center;
                                border: none !important;

                                &:nth-child(n+2) {
                                    background: white;
                                }

                                img {
                                    width: 25px;
                                    height: 20px;
                                }
                            }

                            .tbody {
                                display: grid;

                                div {
                                    padding: 2px 6px;
                                    border-top: 1px solid #C6C6C6;
                                    justify-content: center;
                                }
                            }
                        }
                    }
                }

                .footer {
                    position: absolute;
                    width: 234px;
                    bottom: 24px;
                    display: grid;
                    grid-template-columns: auto 1fr;
                    align-items: end;

                    .number {
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 19.2px;
                    }

                    .site {
                        font-size: 16px;
                        display: flex;
                        flex-direction: row;
                        align-items: end;

                        svg,
                        img {
                            width: 17px;
                            height: 17px;
                        }

                        &>div:last-child {
                            margin-left: 12px;
                        }
                    }

                    &>div:last-child {
                        justify-content: end;
                    }
                }

                .category {
                    position: absolute;
                    display: block;
                    top: $page-category-top;
                    bottom: $page-category-bottom;
                    width: 55px;
                    height: auto;

                    &>div {
                        position: relative;
                        height: 100%;
                        width: 100%;

                        img {
                            position: absolute;
                            width: 55px;
                        }
                    }
                }
            }

            &.left {
                .body {
                    margin-right: auto;
                    margin-left: 0;
                    padding: 0;

                    .header {
                        padding-left: $page-body-indent;
                        padding-right: 0px;
                        grid-template-columns: 1fr auto;
                    }

                    .content {
                        left: $page-body-indent;
                    }

                    .footer {
                        left: $page-body-indent;
                    }

                    .category {
                        left: 0px;

                        img {
                            transform: scale(-1, 1);
                        }
                    }
                }
            }

            &.right {
                .body {
                    margin-left: auto;
                    margin-right: 0;

                    .header {
                        padding-left: 0px;
                        padding-right: $page-body-indent;
                        grid-template-columns: auto 1fr;

                        .title {
                            display: flex;
                            justify-content: end;
                            text-align: right;
                        }

                        .logo {
                            order: -1;
                        }
                    }

                    .content {
                        right: $page-body-indent;
                    }

                    .footer {
                        right: $page-body-indent;

                        .number {
                            justify-content: end;
                            display: flex;
                        }

                        .site {
                            order: -1
                        }
                    }

                    .category {
                        right: 0px;

                        img {
                            transform: scale(1, 1);
                        }
                    }
                }
            }


            &+.page {
                margin-top: 24px;
            }
        }

        img.series {
            max-width: 82px;
            height: 10px;
            display: block;
            margin-bottom: 10px;
        }
    }
}