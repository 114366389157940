.file-downloader {
  & * {
    fill: var(--color-primary-700);
  }
  cursor: pointer;
}

.delete-image-confirmation.custom {
  position: absolute;
  border: 1px solid var(--color-accent-700);
  outline: 4px solid var(--color-accent-100);
  background: var(--color-neutral-0);
  border-radius: 0.75em;
  padding: 1.25em;
  display: flex;

  bottom: 3.75em;
  right: 2.5em;

  gap: 1.25em;

  color: var(--color-texticon-primary);

  .header {
    font-size: var(--size-header-6);
    font-weight: 700;
    line-height: 1.2em;
    color: var(--color-primary-900);
  }

  .header-wrapper {
    display: flex;
    gap: 0.625em;
    flex-direction: column;
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    gap: 0.625em;
  }
}