.tree-container {
  //height: 90vh;
  display: flex;


  &>div {
    flex: 1 1 0;
  }

  .tree-category-group-list-container {
    width: 40%;
    vertical-align: top;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
}