.nofound-wrapper {
  height: 100%!important;
}

.td-loading {
  border: none!important;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

}