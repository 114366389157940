.bullfacts {
  //display: flex;
  //flex-direction: column;
  //width: 100%;
  //
  //.bullfacts__table {
  //  display: flex;
  //  overflow: auto;
  //  scrollbar-gutter: stable;
  //}

  .toolbar-row {
    .selector {
      min-width: 130px;
      flex: 1;
    }
  }

  .wide-input {
    width: 40%;
  }
}