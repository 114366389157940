.statistic-invoices-info{
    font-weight: 400;
    display: flex;
    align-items: center;
}

.invoice-item{
    border-radius: 12px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 4px;
    padding-bottom: 4px;;
}

.total-invoices{
    font-size: var(--size-header-6);
}

.watchtower-droppod {
    gap: 1.25em;
}

.ready-to-ship-invoices{
    background-color: #F0FDF1;
    margin-left: 15px;
}

.in-process-invoices{
    background-color: #FFF3E2;
    margin-left: 15px;
    border-radius: 12px;
}

.statistic {
    display: flex;
    gap: 1.25em;
}
.statistic-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.25em;
    flex: 1;
}

.inline-item{
    display: flex;
    font-weight: 500;
    gap: 0.625em;
}

.inline-item-text{
}

.statistic-value{
    font-size: var(--size-header-4);
    font-weight: var(--fw-heavy-700);
    line-height: normal;
}

.statistic-sub-value{
    font-weight: 500;
    color: var(--color-texticon-primary);
    margin-top: 0.9em;
    display: flex;
    gap: 1.25em;
}

.statistic-sub-value-item{

}

.statistic-card{
    gap: 0.625em;
}

.statistic-chart{
    flex-direction: row;
    min-width: 450px;
    gap: 2.5em;
}

.statistic-container{
	display:flex;
    gap: 1.25em;

    & > div {
        flex: 1;
    }
}

.statistic-total-products-val{
    font-size: var(--size-header-5);
    font-weight: 700;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
}

.chart-wrapper{
    position: relative;
    display: flex;
    align-items: center;
}

.legend-title {
    font-weight: 500;
}

.legend-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.25em;
    flex: 1;
}

.legend-text {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.legend-item {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.legend-color {
    width: 0.625em;
    height: 0.625em;
    border-radius: 50%;
}

.legend-text-line-through {
    text-decoration: line-through;
}
