.file-import {
    height: 100%;
    border-width: 2px;
    border-radius: 0.75em;
    border-style: dashed;
    padding: 20px;
    border-color: var(--color-texticon-primary);
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
    align-items: start;
    text-align: start;

    &>div {
        height: 100%;

        &:first-child {
            svg {
                width: 48px;
                height: 48px;
            }
        }
    }

    &.active {
        grid-template-columns: auto 1fr auto auto;
        background-color: var(--color-warning-100);
        border-color: var(--color-warning-700);
        cursor: default;

        &.error {
            background-color: var(--color-danger-100);
            border-color: var(--color-danger-700);

            .error-text {
                color: var(--color-danger-700);
            }

            &.error-body {
                font-size: var(--size-text-2);
            }
            
        }
    }

    &.success {
        grid-template-columns: auto 1fr auto;
        background-color: var(--color-success-200);
        border-color: var(--color-success-900);
        cursor: default;

        .text {
            color: var(--color-success-700);
            font-size: var(--size-text-1);
            margin-top: 10px;
        }
    }

    .file-replace {
        margin-top: 10px;

        label {
            color: var(--color-dark-gray);
            font-weight: 500;
            font-size: var(--size-text-);
            cursor: pointer;
        }

        input {
            display: none;
        }
    }

    .loading-file {
        width: 48px;
        height: 48px;
        animation: loading-file-rotation 2s linear infinite;
    }

    .orange-text,
    .error-text {
        margin-top: 10px;
    }

    &-percent {
        color: var(--color-texticon-primary);
        font-size: var(--size-text-2);
    }

    &-time {
        font-size: var(--size-text-3);
        color: var(--color-gray);
        margin-right: -4px;
        height: 100%;
    }

    &-close {
        height: 100%;

        svg {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }

    input[type=file] {
        display: none;
    }

    .body {
        font-size: var(--size-text-1);

        .file-orange-text {
            display: initial !important;
            color: var(--color-secondary-700);
        }
    }

    @keyframes loading-file-rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    p {
        margin: 0;
    }
}