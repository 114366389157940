.product-attributes-block-buttons-header {
  display: flex;
  font-size: 13px;
  flex-wrap: wrap;
  margin-top: 9px;

  & > * {
    margin-bottom: 3px;
    margin-top: 3px;
  }

  .form-control {
    font-size: 13px;
    margin-right: 10px;
  }

  .btn {
    margin-right: 10px;
  }

  .selector {
    margin-right: 10px;
  }

  .selector--name {
    min-width: 600px;
  }
}

.important-attribute {
  //background-color: #99edc3;
}

.pab-table-wrapper {
  //margin-top: 9px;
  //overflow: auto;
  //max-height: 800px;
  //scrollbar-gutter: stable;
  max-height: calc(var(--offset-height) - var(--header-height) - 0.625 * 2 * var(--size-text-1) - 0.4 * var(--size-text-1));

  & input {
    width: 80px;
  }
}

.fake-input {
  box-sizing: border-box;
  font-weight: 400;
  padding: 0.25em 0.5em;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 0.2em;

  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;

  text-align: center;
  width: 26.72px;
  overflow: hidden;

  &.disabled {
    background-color: #e9ecef;

  }
}








.bg-selected {
  background: var(--color-accent-700);
  color: var(--color-primary-100);
}

.attribute-input {
  flex: none;
  width: 90px;

  input {
    width: 100%;
  }
}

.selector--name {
  flex: 1;
}

.orange-circle-with-number {
  width: 1.4em;
  height: 1.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-secondary-700);
  border-radius: 50%;
  color: var(--color-primary-100);
  font-weight: var(--fw-medium-500);

  &.d1 {
    background: transparent;
    & * {
      fill: var(--color-secondary-700);
    }
  }
}