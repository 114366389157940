.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.75em;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.form-file-upload {
  max-width: 100%;
  text-align: center;
  position: relative;
  font-size: var(--size-text-2);
}

.input-file-upload {
  display: none;
}

.label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 0.75em;
  border-style: dashed;
  padding: 1em;
  border-color: var(--color-texticon-primary);
  cursor: pointer;
}

.grid-wrapper {
  display: grid;
  grid-auto-flow: column;
  gap: 0.5em;
  align-items: center;
  text-align: left;

  p {
    margin: 0;
  }
}

.file-name-wrapper {
  display: grid;
  grid-auto-flow: column;
  gap: 0.5em;
}

.orange-text {
  color: var(--color-secondary-700);
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

.download {
  width: 63px;
  height: 63px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}