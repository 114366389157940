@import "./assets/styles/font-face.scss";
@import "./assets/styles/font-size.scss";
@import "./assets/styles/colors.scss";
@import "./assets/styles/buttons.scss";
@import "./assets/styles/new-utils.scss";

html,
body,
#root {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  background-color: var(--color-neutral-200);
  /*overflow: hidden;*/
}

body {
  margin: 0;
  font-family: 'Commissioner', -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: var(--size-text-1);
  color: var(--color-primary-700);
  scrollbar-width: thin;
}

:root {
  scrollbar-width: thin;
  scrollbar-color: var(--color-neutral-300) #ffffff00;

  ::-webkit-scrollbar {
    width: 0.375em;
    height: 0.375em;
    //height: 0;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f102;
    //margin: 0.5em;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--color-neutral-300);
    border-radius: 5px;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

input[type=checkbox] {
  margin: 4px;
}