.bg-green {
  background-color: #2ECC40
}

.bg-yellow {
  background-color: #FFDC00
}

.bg-orange {
  background-color: #FF851B
}

.bg-red {
  background-color: #FF4136
}

.bg-blue {
  color: #007bff;
}