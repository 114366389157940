.cat-container {
  //height: 90vh;
  display: flex;

  &>div {
    flex: 1 1 0;
  }

  .cat-category-group-list-container {
    //height: 90vh;
    width: 40%;
    vertical-align: top;
    padding-left: 10px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
  }
}

.page-categories {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;

  .default-catagories {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;

    & > div{
      overflow: hidden;
    }
  }

  &.image-settings {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;

    .default-catagories {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      grid-template-rows: auto 1fr;
    }
  }
}