.product-left-column-table {

  flex: 1;

  tr {
    position: relative;
  }

  .u-table {
    min-height: 100%;
  }

  td:first-child, th:first-child {}



  td:last-child,th:nth-child(3) {
    width: 100%;
  }

  .label {
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
  }
}