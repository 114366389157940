.admin-page {
  .u-input {
    flex: 0;
    width: fit-content;
    margin-bottom: 0.5em;
  }

  .u-button {
    margin-top: 1.25em;
    width: fit-content;
  }
}