.new-product-pb-toolbar__first-row-wrapper {
  //display: flex;
  //margin-top: 10px;
  //align-items: center;
  //flex-wrap: wrap;
  //justify-content: flex-end;
  //
  //> * {
  //  margin-bottom: 4px;
  //}

  //.input--id {
  //  max-width: 80px;
  //  margin-right: 20px;
  //  flex-shrink: 0;
  //}

  .input--id {
    min-width: 100px;
    max-width: 120px;
    & input {
      min-width: 100px;
    }
  }

  .input--name {
    //margin-right: 20px;
    //flex: 1 0.5 0;
    min-width: 280px;
  }

  input[type=checkbox] {
    margin: 4px;
  }

  .input-checkboxes-wrapper {
    margin-right: 10px;
  }

  //button {
  //  margin: 0 3px;
  //}

  .back-to-main > span > a {
    color: white;
    text-decoration: none;
  }


}

.toolbar-row.select-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}