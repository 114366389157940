:root {
  --color-neutral-0: #FFFFFF;
  --color-neutral-100: #E3E3E3;
  --color-neutral-200: #F1F2F9;
  --color-neutral-300: #A9ACB6;

  --color-primary-100: #FEFEFF;
  --color-primary-700: #2C2929;
  --color-primary-900: #2C2929;

  --color-secondary-100: #E1E1F0;
  --color-secondary-700: #EC8738;
  --color-secondary-900: #2F2F34;

  --color-accent-100: #EFF7FF;
  --color-accent-700: #3A8FDD;

  --color-success-100: #F0FDF8;
  --color-success-200: #F0FDF1;
  --color-success-700: #159469;
  --color-success-900: #066042;

  --color-warning-100: #FFF3E2;
  --color-warning-700: #F98B36;
  --color-warning-900: #CD5E24;

  --color-danger-100: #FFEEEF;
  --color-danger-700: #C92D3A;
  --color-danger-900: #951D27;

  --color-texticon-primary: #686868;
  --color-texticon-secondary: #222224;
  --color-texticon-disabled: #1D2433A6;

  --color-gray: #A9ABB6;
  --color-dark-gray: #33333D;


}