.translator-page {

  .selector {
    min-width: 0;
  }

  .header-text {
    white-space: nowrap;
    margin-right: 2.75em;

  }

  .flex-none {
    flex: none;
  }

  .flex-1 {
    flex: 1;
  }

  .u-droppod-header {
    justify-content: space-between;
  }

  .u-table__scroll-wrapper {
    flex: 1;
  }

  .u-table {
    min-height: 100%;
  }

}