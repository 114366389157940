.u-button {


  display: flex;
  align-items: center;
  justify-content: center;

  font-size: var(--size-text-1);
  padding: 0.75em 1.25em;  /*  12px 20px  */
  font-weight: var(--fw-medium-500);
  border: none;
  border-radius: 0.75em;
  cursor: pointer;
  box-shadow: none;
  white-space: nowrap;


  outline: none;

  color: var(--color-primary-100);
  & * {
    fill: var(--color-primary-100);
    transition: fill .15s ease-in-out;
  }

  transition: all .15s ease-in-out;


  & .link-icon {
    margin-right: 0.5em;
  }


  /*   SIZES   */

  &.large {
    font-size: var(--size-text-1);
    padding: 0.75em 1.25em;  /*  12px 20px  */
    border-radius: 0.75em; /*  12px  */
  }

  &.medium {
    font-size: var(--size-text-1);
    padding: 0.625em 0.75em;  /*  10px 12px  */
    border-radius: 0.75em; /*  12px  */
  }

  &.small {
    font-size: var(--size-text-2);
    padding: 0.714em 0.86em;  /*  8px 10px  */
    border-radius: 0.57em; /*  8px  */
  }

  &.square {
    font-size: var(--size-text-1);
    padding: 0.75em 0.75em;  /*  12px 12px  */
    border-radius: 0.75em; /*  12px  */
  }



  /*   COLORS   */

  &.primary {
    background-color: var(--color-primary-700);
    color: var(--color-primary-100);
    & * {
      fill: var(--color-primary-100);
    }

    &:active,
    &.active {
      outline: 1px solid var(--color-neutral-300);
    }
  }

  &.secondary {
    background-color: var(--color-primary-100);
    color: var(--color-texticon-primary);
    & * {
      fill: var(--color-texticon-primary);
    }

    &:hover {
      background-color: var(--color-accent-100);
    }

    &:active,
    &.active {
      background-color: var(--color-accent-700);
      color: var(--color-primary-100);
      & * {
        fill: var(--color-primary-100);
      }
    }
  }

  &.outline {
    background-color: var(--color-primary-100);
    color: var(--color-primary-700);
    & * {
      fill: var(--color-primary-700);
    }
    border: 2px solid var(--color-primary-700);

    &:active,
    &.active {
      outline: 1px solid var(--color-neutral-300);
    }
  }

  &.text {
    background-color: transparent;
    color: var(--color-primary-700);

    & * {
      fill: var(--color-primary-700);
    }

    &:hover {
      color: var(--color-secondary-700);

      & * {
        fill: var(--color-secondary-700);
      }
    }

    &:active,
    &.active {
      border: 1px solid var(--color-neutral-300);
    }

    &:disabled {
      border: 1px solid var(--color-neutral-300);
      color: var(--color-texticon-primary);
      & * {
        fill: var(--color-texticon-primary);
      }
      background-color: var(--color-neutral-200);
    }
  }

  &.accent {
    background-color: var(--color-secondary-700);
    color: var(--color-primary-100);
    & * {
      fill: var(--color-primary-100);
    }

    &:hover {
      background-color: var(--color-primary-700);
    }

    &:active,
    &.active {
      background-color: var(--color-secondary-700);
    }
  }



  /*   COMMON EFFECTS   */

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &:active,
  &.active {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  }

  &:disabled {
    color: var(--color-primary-100);
    & * {
      fill: var(--color-primary-100);
    }
    background-color: var(--color-neutral-300);
    border: none;
    cursor: not-allowed;

    &:hover {
      color: var(--color-primary-100);
      & * {
        fill: var(--color-primary-100);
      }
      background-color: var(--color-neutral-300);
      border: none;
    }
  }
}