.category-images {
    .empty {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        text-align: center;

        svg {
            width: 72px;
            height: 72px;
            margin-bottom: 20px;
        }

        &-text {
            font-size: 20px;
            color: var(--color-texticon-primary);
        }
    }

    &-header {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: 10px;
        align-items: center;
        margin-top: 10px;

        .title {
            font-size: 24px;
            font-weight: 700;
            color: var(-color-dark-gray);
        }

        .count {
            font-size: 16px;
            color: var(--color-warning-700);
        }
    }

    &-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        margin-top: 20px;

        .item-image {
            .view {
                position: relative;
                margin-top: 10px;

                .image {
                    display: block;
                    width: 100%;
                    height: 162px;
                    object-fit: contain;
                    background: var(--color-neutral-200);
                }

                .control {
                    position: absolute;
                    top: 7px;
                    right: 7px;

                    svg {
                        cursor: pointer;

                        path {
                            fill: #000000 !important;
                        }
                    }
                }
            }
        }
    }
}