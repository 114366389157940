//.product-left-column-toolbar {
//  margin-top: 0.625em;
//  margin-bottom: 0.625em;
//  display: flex;
//
//  & .u-search-input {
//    margin-right: 0.625em;
//  }
//
//  & .selector {
//    min-width: 250px;
//
//    @media screen and (max-width: 1600px){
//      min-width: 180px;
//    }
//  }
//}

.product-left-column-selector-container {
  width: 200px;
}
/*

.product-left-column-toolbar {
  .toolbar-row .selector {
    width: 100%;
  }

}
*/


.product-left-column-add-button {
  margin-left:10px
}

.product-left-column-search {
  float:right
}
