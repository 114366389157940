.upload-data {
  //display: flex;
  //flex-direction: column;

  &-header{
    display: grid;
    grid-template-columns: minmax(auto, 490px) 1fr 1fr;
    grid-column-gap: 10px;
    align-items: center;
  }

  .toolbar-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }

  .header-text {
    font-size: var(--size-header-4);
    font-weight: var(--fw-heavy-700);
    line-height: 1.85em;
  }

  .u-search-input {
    margin-right: 0.625em;
  }

  span {
    display: flex;
    align-items: center;
  }

  .toolbar-row {
    .u-input {
      min-width: 80px;

      & input {
        min-width: 80px;
        width: 100%;
      }
    }

    &.second {
      .selector {
        min-width: 0;
        flex: 1;

        &:last-of-type {
          min-width: 180px;
        }
      }
    }
  }



  .upload-data__table {
    //display: flex;
    //overflow: auto;
    //scrollbar-gutter: stable;
  }
}

//.upload-data__head-wrapper {
//  display: flex;
//  flex-direction: row;
//
//  .upload-data__head-column {
//    display: flex;
//    flex-direction: column;
//
//    & > * {
//      margin: 7px;
//    }
//
//    .bordered-table {
//      border: 1px solid;
//      border-radius: 7px;
//      padding: 1em;
//    }
//  }
//}