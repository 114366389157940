.product-right-column-table {
  //font-size: 13px;
  //line-height: 1em;

  //height:92%;
  //overflow-y: auto;

  flex: 1;

  .u-table {
    min-height: 100%;
  }
}

//.toggling-view-code-column {
//  width: 60px;
//}

//.detail-view {
//  display: none;
//
//  &:hover {
//    background-color: transparent !important;
//  }
//
//  &.-open {
//    display: table-row;
//  }
//}

.product-right-column-plus-wrapper {
  //width: 30px;
  //text-align: center;
  //vertical-align: middle !important;
  min-width: 12px;
  width: 12px;
  box-sizing: content-box;
}


//.info {
//  width: 30px;
//  text-align: center;
//  vertical-align: middle !important;
//}