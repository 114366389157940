.recommendations {

  .recommendations__table {
    margin-top: 0.625em;
  }

  .u-search-input {
    margin-left: auto;
    margin-right: 0.625em;
    flex: 0;
    min-width: 300px;

  }

  //div.search-wrapper {
  //  margin-left: auto;
  //  margin-right: 0;
  //}
  //
  //.checkbox-cell {
  //  text-align: center;
  //}
  //
  //.table {
  //  font-size: 15px;
  //}
  //
  //&.recommendations-wrapper {
  //  display: flex;
  //  flex-direction: column;
  //  width: 100%;
  //
  //  .recommendations__table {
  //    display: flex;
  //    overflow: auto;
  //    scrollbar-gutter: stable;
  //  }
  //}
}