.u-no-padding {
    padding: 0;
}

.u-center-text {
    text-align: center;
    vertical-align: middle;
}

.u-width-30 {
    width: 30px;
    min-width: 30px;
}

.u-width-100 {
    width: 100px;
    min-width: 100px;
}

.u-flex-column {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
}

.u-buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 9px;
    margin-bottom: 9px;

    & > * {
          margin-bottom: 3px;
          margin-top: 3px;
          margin-right: 10px;
      }
}

//.u-wide-th {
//    & > * {
//        padding: 0.75rem !important;
//    }
//}




.my-custom-checkbox__wrapper {
    display: flex;
}

/* для элемента input c type="checkbox" */
.my-custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

/* для элемента label, связанного с .my-custom-checkbox */
.my-custom-checkbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    margin: 0;
}

/* создание в label псевдоэлемента before со следующими стилями */
.my-custom-checkbox+label::before {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    padding: 0.1em;
    width: 1em;
    height: 1em;
    font-size: 15px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #CCC;
    border-radius: 0.25em;
    background: white;
    color: black;
}

/* стили для чекбокса, находящегося в состоянии checked */
.my-custom-checkbox:checked+label::before {
   content: "\2713";
}

/* стили для чекбокса, находящегося в состоянии disabled */
.my-custom-checkbox:disabled+label::before {
    background-color: #e9ecef;
}


// табличные дела

tr {
    &.--selected {
        background-color: #007bff;
        color: white;

        &.--grey {
            background-color: #00000013;
            color: black;
        }

        &.--yellow {
            background-color: yellow;
            color: black;
        }
    }
}

//.tab-pane {
//    font-size: 13px;
//}

// черные кнопочки

//.new-product-toolbar__first-row-wrapper,
//.tab-content {
//    .btn.btn-dark {
//        font-size: 14px;
//        padding: 0;
//        height: 30px;
//        min-width: 30px;
//
//        span {
//            margin: 0 0.5em;
//        }
//    }
//}

// селектор

.u-13-selector {
    font-size: 13px;
}

// инпуты

.u-narrow-input {
    padding-bottom: 0;
    padding-top: 0;
    font-size: 13px;
}

.u-width-auto {
    width: auto;
}

.u-align-center {
    text-align: center;
}

.u-flex-grow {
    flex-grow: 1;
}

.u-flex-no-srink-inside {
    & > * {
        flex-shrink: 0;
    }
}

.u-flex {
    display: flex;
}

.u-no-link {
    color: white;
    text-decoration: none;
}

.u-min-w-100 {
    min-width: 100px;
}

.u-max-w-100 {
    max-width: 100px;
}


.u-min-w-320 {
    min-width: 320px;
}

.u-min-w-400 {
    min-width: 400px;
}

.u-min-w-600 {
    min-width: 600px;
}