.toolset-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .toolbar-row .selector {
    flex: 1;
  }

  .toolset__table {
    display: flex;
    overflow: auto;
    scrollbar-gutter: stable;
  }
}