.ad-result-modal__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(17, 17, 17, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;

  .ad-result-modal {
    padding: 100px 28px;
    align-items: center;
    font-size: var(--size-header-6);
    font-weight: var(--fw-medium-500);
    min-width: 30%;

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }

  .u-status-icon {
    width: 4.5em;
    height: 4.5em;
  }
}