.video-wrapper {
  height: 1px;
  // firefox
  min-height: 1px;
  max-height: 200px;
  text-align: center;
}

.video-responsive {
  height: 100%;

  iframe {
    height: 100%;
    width: 100%;
  }
}