.compare-content {

  .u-droppod-header {
    justify-content: space-between;
  }

  .header-text {
    padding-right: 2.75em;
    margin-right: 0;
  }

  .u-toolbar {
    flex: 1;
  }

  .compare-content__table-wrapper {
    flex: 1;
  }
}