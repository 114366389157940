.cat-selector-container {
  margin-top: 15px;
  vertical-align: top;
  max-width: 250px;
}

.cat-selector-container-inner {
  margin-left: 10px;
  margin-bottom: 20px;
  line-height: 1.5;
  font-size: 12px;
  width: 220px;
}