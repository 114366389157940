.load-modal {
  position: fixed;
  bottom: 2.5em;
  right: 1.25em;
  gap: 1.25em;

  min-width: 450px;

  .u-droppod-section-header {
    margin: 0;
  }

  .progressbar-wrapper {
    height: 1.25em;
    background: linear-gradient(28deg, #D76F1F -28.65%, #EC8738 22.16%, rgba(236, 135, 56, 0.00) 107.99%);
    border-radius: 2em;
    position: relative;
    overflow: hidden;

    .progressbar-slider {
      height: 100%;
      right: 0;
      position: absolute;
      border-bottom-right-radius: 2em;
      border-top-right-radius: 2em;
      margin-left: auto;
      background: radial-gradient( circle at 0, transparent 0.625em, var(--color-neutral-200) 0.6251em, var(--color-neutral-200) 100%);
    }


  }

  .progressbar {
    position: relative;

    .progressbar-percent {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 100%;
      text-align: center;
      font-size: var(--size-text-2);
      color: var(--color-texticon-primary);
    }

    .progressbar-text {
      font-size: var(--size-text-2);
      color: var(--color-texticon-primary);
      margin-top: 0.5em;
    }
  }
}