.image-blocks-grid {
  display: grid;
  gap: 0.625em 1.25em;
  grid-template-columns: repeat(3, 1fr);
}

.middle-block__ala-image {
  display: block;
  width: 100%;
  height: 180px;
  object-fit: contain;
  background: var(--color-neutral-200);
}

.close-video {
  position: absolute;
  left: 100%;
  bottom: 100%;
  background: white;
  padding: 0.5em;
  box-sizing: content-box;
  border-radius: 50%;
  cursor: pointer;
}

.youtube-modal {
  .modal-body {
    display: flex;
    flex-direction: column;
  }
  .modal-content {
    min-height: 480px;

  }

  .video-responsive {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  iframe {
    flex: 1;
  }
}

.block-wrapper {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 0.625em;
  position: relative;

  .image-name {
    overflow-wrap: anywhere;
  }

  .image-wrapper {
    position: relative;
    overflow: hidden;
  }

  .image-date {
    color: var(--color-neutral-300)
  }

  .in-round {
    padding: 4px;
    box-sizing: content-box;
    border-radius: 50%;
    background: var(--color-neutral-0);
    cursor: pointer;

    & * {
      fill: var(--color-texticon-primary);
    }
  }

  .tags-wrapper {
    display: flex;
    position: absolute;
    top: 0.3125em;
    left: 0.3125em;
    gap: 0.3125em;
  }

  .share-wrapper {
    display: flex;
    position: absolute;
    bottom: 0.3125em;
    right: 0.3125em;
    gap: 0.3125em;
  }

  .play-circle {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
  }

  .close-i {
    position: absolute;
    top: 0.3125em;
    right: 0.3125em;
  }

  .selectors-edit-wrapper {
    display: grid;
    gap: 0.625em;
  }

  .delete-image-confirmation {
    position: absolute;
    border: 1px solid var(--color-accent-700);
    outline: 4px solid var(--color-accent-100);
    background: var(--color-neutral-0);
    border-radius: 0.75em;
    padding: 1.25em;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--color-texticon-primary);

    .header {
      font-size: var(--size-header-6);
      font-weight: 700;
      line-height: 1.2em;
      color: var(--color-primary-900);
    }

    .buttons-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
    }
  }

}

.deleted-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-texticon-primary);
  justify-content: center;
  overflow-wrap: anywhere;
}

.files-holder {
  display: flex;
  flex-direction: column;
  gap: 0.625em;
}

.file-load-modal-body-item {
  display: flex;
  gap: 0.625em;

  .good,
  .bad,
  .load {
    display: none;
    width: 3em;
    height: 3em;
  }

  &.green {
    .good {
      display: block;
    }

    .status-text {
      color: var(--color-success-700);
    }
  }

  &.red {
    .bad {
      display: block;
    }

    .status-text {
      color: var(--color-danger-700);
    }
  }

  &.orange {
    .load {
      display: block;
    }

    .status-text {
      color: var(--color-warning-700);
    }
  }

  .time {
    color: var(--color-neutral-300);
    font-size: var(--size-text-3);
  }

  .info-holder {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.625em;
  }
}