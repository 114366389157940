.loading-wrapper {
  height: 100%!important;
}

.td-not-found {
  border: none!important;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

  .not-found-icon {
    height: 4.5em;
    width: 4.5em;
    margin-bottom: 1.5em;
    & * {
      fill: var(--color-neutral-300);
    }

  }

  .not-found-text {
    font-size: var(--size-header-6);
    font-weight: var(--fw-medium-500);
    color: var(--color-texticon-primary);
  }
}