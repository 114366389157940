.translate-form {

    .u-droppod-scroll-wrapper {}

    .u-button {
      width: fit-content;
      font-weight: var(--fw-normal-400);
      margin-bottom: 0.625em;
    }

    .form-inline {
      width: max-content;
      padding: 20px;
      border: 2px solid black; border-radius: 5px; margin-top: 20px;
    }

    #translatorPage {
      margin: 10px auto auto;
    }

    .selectors-column-wrapper {
      display: flex;
      margin-bottom: 0.5em;

      & > * {
        margin-right: 1.25em;
      }
    }

    .u-droppod-item-header {
      margin-bottom: 1em;
    }


    .u-droppod-info {
      margin-top: calc(0.5*var(--size-text-1));
      margin-bottom: calc(0.625*var(--size-text-1));
    }

    .link-icon {
      margin-right: 0.5em;
    }

    .accent {
      margin-bottom: 0;
    }
}