.layout {
  display: flex;


  height: 100vh;
  overflow: hidden;
  //flex-direction: column;
}

.layout-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  min-width: 0;
  padding: 2.5em 1.25em;
  overflow: hidden;

  .layout-body {
    display: flex;
    flex: 1;
    min-height: 0;
    padding-top: 1.25em;

    & > * {
      flex: 1 1;
      margin-left: 1.25em;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }


}