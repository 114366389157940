.nav-tabs {
  //border-color: var(--color-neutral-100);
  border-bottom: none;
}

.nav-item > button.nav-link {

  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--color-neutral-100);
  background: transparent;
  color: var(--color-neutral-300);
  padding-inline: 3.75em;
  font-weight: var(--fw-medium-500);

  &.active {
    color: var(--color-accent-700);
    border-bottom: 2px solid var(--color-accent-700);
  }

  &:focus {
    outline: none;
  }
}

.tabs-wrapper {
  margin-top: .375em;
  margin-bottom: .375em;
}



.tab-content {
  display: flex;
  flex: 1;
  //overflow: auto;
  flex-direction: column;
  min-height: 0;

  .u-droppod-scroll-wrapper {
    flex: 1;
  }

  .u-table__scroll-wrapper {
    flex: none;
  }

  .u-droppod {
    padding: 0 0 0.4em;
    border-radius: 0;
    box-shadow: none;
    border: none;
  }

  .active {

    &.u-flex-column {
      display: flex;
      min-height: 0;
    }

    .tab-content {
      display: flex;
      flex: 1;
    }

    &.tab-pane {
      display: flex;
      //flex-direction: column;
      //min-height: 0;
      flex: 1;

      //&#products {
      //  min-height: auto;
      //}
    }
  }





  .textarea-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.625em;
    flex: 0;
    @media screen and (max-width: 1400px) {
      flex: 1;
    }
  }

  .text-part {
    margin-right: 3.75em;
    flex: 1;
  }



}

.common-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;

  & > * {
    flex: 1;
  }

  textarea,
  .u-textarea {
    flex: 1;
  }
}

.row-or-column {
  display: flex;
  min-height: 100%;
  overflow: hidden;
  @media screen and (max-width: 1400px) {
    overflow: auto;
    flex-direction: column;

    .common-wrapper {
      flex-direction: row;

      & > :first-child {
        margin-right: 1em;
      }
    }

    .text-part {
      margin-right: 0;
    }
  }
}

.graphic-part {
  flex: 1.5!important;
  .u-droppod-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .u-toolbar {
    margin: 0;
  }
}