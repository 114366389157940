.navbar-wrapper {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .buttons-wrapper {
    display: flex;
    align-items: center;

    & > * {
      margin: 0 0 0 1.25em;
    }
  }

  .logout-icon {
    cursor: pointer;
  }

  .path-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .chevron-icon {
    width: 1em;
    height: 1em;
    margin-top: 3px;
  }

  .path-link {
    padding-inline: 0.714em;
  }

}