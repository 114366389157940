.login-background {
  background-color: var(--color-neutral-0);
  display: flex;
  flex: 1;
}

.login-form-wrapper {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 3.4375em 2.125em;
  width: 100%;
  max-width: 564px;
  border-radius: 0.75em;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.12);
  border: 1px solid var(--color-neutral-200);

  & .last-input {
    margin-bottom: 1.125em;
  }


  .login-form {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .u-input {
    margin-bottom: 1.5em; /*  24px  */
  }

  .login-error {
    margin-top: 0.5em;
    font-size: var(--size-text-2);
    color: var(--color-danger-700);
    text-align: center;
    //visibility: hidden;
  }

  .password-forgot {
    color: var(--color-warning-700);
    font-weight: var(--fw-normal-400);
    text-decoration: none;
    text-align: center;
    margin-top: 1.25em;
  }

  .logo-text {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}