.cat-product-group-list-container {
  //width: 45%;
  //border: 1px none;
  //border-left-style: solid;
  //padding-left: 10px;
  //display: flex;
  //flex-direction: column;
}

.product-group-settings {
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 8px;
  margin-left: 20px;
  margin-right: 20px;
}

