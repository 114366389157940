.product-body {
  //height: 90vh;
  display: flex;
  //flex: 1 1;
}

//.product-left-column {
//  flex: 1 1 0;
//  display: flex;
//  flex-direction: column;
//}

//.product-right-column {
//  flex: 1 1 0;
//  margin-left: 1.25em;
//  display: flex;
//  flex-direction: column;
//}