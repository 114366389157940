.category-group-toolbar {
    
    &-search{
        grid-template-columns: 1fr 263px;
    }
    
    &-create{
        grid-template-columns: 1fr auto;
    }

    &-search,
    &-create {
        display: grid;
        grid-column-gap: 10px;
        margin-bottom: 0.625em;
        margin-top: 0.625em;
    }
}