.cat-product-group-list-toolbar-container {
//  margin-bottom:15px;
//  margin-top:15px
//}
//
//.cat-product-group-list-toolbar-select-container {
//  width: 280px !important;
//  margin-left:10px

  .small-selectors .selector {
    min-width: 140px;
    flex: 1 1;

    @media screen and (max-width: 1600px) {
      min-width: 120px;
    }
  }


}