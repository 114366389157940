.ad-characteristics {
  .u-icon--green {
    & * {
      fill: var(--color-success-700);
    }
  }

  .u-icon--grey {
    & * {
      fill: var(--color-texticon-primary);
    }
  }
}