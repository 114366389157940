.u-input {
  display: flex;
  flex-direction: column;
  flex: 1;

  &.sized-input {
    flex: 0;

    & input {
      flex: 0;
    }
  }

  & label {
    font-weight: var(--fw-medium-500);
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    & span {
      margin-bottom: 0.5em;
    }
  }

  & .input-with-button-wrapper {
    display: flex;
    position: relative;
  }

  &.no-controls {
    & input {
      padding: 0.7em 0.8em;
    }

    & .text-input-icon {
      display: none !important;
    }
  }

  & input {
    flex: 1;
    border-radius: 0.75em;
    /*  12px  */
    border: 1px solid var(--color-neutral-300);
    padding: 0.7em calc(0.8em + var(--size-header-5)) 0.7em 0.8em;

    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, outline .15s ease-in-out;
    outline-color: transparent;
    width: 100%;

    &:focus,
    &:focus-visible {
      outline: 4px solid var(--color-accent-100);
      border-color: var(--color-accent-700);

      &+.text-input-icon {
        display: block;
      }

    }

    &:disabled {
      border-color: var(--color-secondary-100);
      background-color: var(--color-neutral-200);
      color: var(--color-texticon-primary);
    }
  }

  & .input-icon {
    position: absolute;
    right: 0.6em;
    top: 0;
    bottom: 0;
    margin: auto;


    &.text-input-icon,
    &.input-success-icon,
    &.input-alert-icon {
      display: none;
    }
  }

  & .info-text {
    font-weight: var(--fw-normal-400);
    font-size: var(--size-text-2);
    color: var(--color-texticon-primary);
    margin-top: 0.55em;
    /*  8px  */
  }

  &.error {

    & .input-alert-icon {
      display: block;
    }

    & input {
      border-color: var(--color-danger-700);

      &:focus,
      &:focus-visible {
        outline: 4px solid var(--color-danger-100);

        &~.input-alert-icon {
          display: none;
        }
      }
    }


    & .info-text {
      color: var(--color-danger-700);
    }
  }

  &.success {

    & .input-success-icon {
      display: block;
    }

    & input {
      border-color: var(--color-success-700);

      &:focus,
      &:focus-visible {
        outline: 4px solid var(--color-success-100);

        &~.input-success-icon {
          display: none;
        }
      }
    }

    & .info-text {
      color: var(--color-success-700);
    }
  }
}

.u-search-input {
  display: flex;
  flex-direction: column;
  flex: 1;


  & .input-with-button-wrapper {
    display: flex;
    position: relative;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-neutral-300);
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--color-neutral-300);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--color-neutral-300);
  }

  & input {
    flex: 1;
    border-radius: 0.75em;
    /*  12px  */
    border: 1px solid var(--color-neutral-200);
    background-color: var(--color-neutral-200);
    color: var(--color-neutral-300);

    padding: 0.7em 0.8em 0.7em 3em;

    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, outline .15s ease-in-out;
    outline-color: transparent;


    &:focus,
    &:focus-visible {
      background-color: var(--color-primary-100);
      outline: 4px solid var(--color-accent-100);
      border-color: var(--color-accent-700);

      &+.input-icon {
        display: block;
      }

    }
  }

  .search-icon * {
    fill: var(--color-neutral-300);
  }

  &.prototype {

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--color-texticon-primary);
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--color-texticon-primary);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--color-texticon-primary);
    }

    & input {
      border-color: var(--color-texticon-primary);
      background-color: var(--color-primary-100);
      color: var(--color-texticon-primary);
    }

    .search-icon * {
      fill: var(--color-texticon-primary);
    }
  }

  &.dark-text {
    & input {
      color: var(--color-texticon-primary);
    }

    .search-icon * {
      fill: var(--color-texticon-primary);
    }
  }

  & .search-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1.125em;
    margin: auto;

    & * {
      transition: fill .15s ease-in-out;
    }
  }

  & .input-icon {
    position: absolute;
    right: 0.6em;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  & .info-text {
    font-weight: var(--fw-normal-400);
    font-size: var(--size-text-2);
    color: var(--color-texticon-primary);
    margin-top: 0.55em;
    /*  8px  */
  }

}

.u-textarea {
  display: flex;
  flex-direction: column;
  position: relative;

  &.dynamic-textarea {
    word-break: break-word;

    & .textarea {
      padding: 0.7em calc(0.8em + var(--size-header-5)) 0.7em 0.8em;

      max-height: calc(0.7em * 2 + 1em * var(--rows) + 2px);
      overflow: overlay;
    }

    & .input-icon {
      position: absolute;
      right: 0.6em;
      top: 0;
      bottom: 0;
      margin: auto;

      display: none;
    }
  }

  & textarea,
  & .textarea {
    border: 1px solid var(--color-neutral-300);
    border-radius: 0.75em;
    padding: 0.7em 0.8em 0.7em 0.8em;
    //line-height: 1.2em;

    &:focus,
    &:focus-visible {
      outline: 4px solid var(--color-accent-100);
      border-color: var(--color-accent-700);

      &+.input-icon {
        display: block;
      }
    }

    &[contenteditable="false"],
    &:disabled {
      border-color: var(--color-secondary-100);
      background-color: var(--color-neutral-200);
      color: var(--color-texticon-primary);
      box-sizing: content-box;
      min-height: 1em;
    }
  }


  &.error {

    & .input-alert-icon {
      display: block;
    }

    & textarea,
    & .textarea {
      border-color: var(--color-danger-700);

      &:focus,
      &:focus-visible {
        outline: 4px solid var(--color-danger-100);

        &~.input-alert-icon {
          display: none;
        }
      }
    }


    & .info-text {
      color: var(--color-danger-700);
    }
  }


  &.success {

    & .input-success-icon {
      display: block;
    }

    & textarea,
    & .textarea {
      border-color: var(--color-success-700);

      &:focus,
      &:focus-visible {
        outline: 4px solid var(--color-success-100);

        &~.input-success-icon {
          display: none;
        }
      }
    }

    & .info-text {
      color: var(--color-success-700);
    }
  }

}


.--show {
  display: block;
}

.--hide {
  display: none !important;
}

.u-icon {
  display: block;
  width: var(--size-header-5);
  height: var(--size-header-5);
}

.u-droppod {
  display: flex;
  flex-direction: column;
  padding: 1.25em;
  /*  20px  */
  border-radius: 0.75em;
  /*  12px  */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.06);
  border: 1px solid var(--color-neutral-200);
  background-color: var(--color-neutral-0);
}

.u-droppod-header {
  display: flex;
  align-items: center;

  padding-bottom: 0.625em;
  border-bottom: 1px solid var(--color-secondary-100);


  & .header-text {
    font-size: var(--size-header-4);
    font-weight: var(--fw-heavy-700);
    line-height: 1.85em;
    margin-right: auto;
  }
}

.u-droppod-section-header {
  font-size: var(--size-header-5);
  font-weight: var(--fw-bold-600);
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.u-droppod-section-header__small {
  font-size: var(--size-header-6);
  font-weight: var(--fw-bold-600);
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.u-droppod-item-header {
  font-weight: var(--fw-medium-500);
  margin-bottom: 0.5em;
}

.u-droppod-info {
  font-size: var(--size-text-2);
  color: var(--color-texticon-primary);
}

.u-droppod-scroll-wrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;
}



.u-table__scroll-wrapper {

  //noinspection CssInvalidPropertyValue
  overflow-y: overlay;
  scrollbar-width: thin;
}


.u-table {
  font-size: var(--size-text-2);
  line-height: 1em;
  width: 100%;
  overflow: hidden;

  margin-bottom: 0;

  &:focus,
  &:focus-visible {
    outline: none;
  }

  .u-thead {
    background-color: var(--color-neutral-200);
    font-size: var(--size-text-1);
    font-weight: var(--fw-heavy-700);

    & tr {
      border-bottom: 1px solid var(--color-neutral-100);
    }

    & th {
      padding: 0.875em 0.625em;
      /*  14px 10px  */
      border: none;
      text-align: left;
    }

    &.fixed-head {
      position: sticky;
      top: 0;
      z-index: 1;
      box-shadow: 0 -1px 0 0 var(--color-neutral-100) inset;
    }
  }

  .u-tbody {
    --shift-amount: 1;

    .top-border {
      td {
        border-top: 1px solid var(--color-neutral-100);
      }
    }

    & tr {
      height: 0;

      &:last-of-type {
        height: 100%;
      }

      &:hover {
        background-color: var(--color-accent-100);
      }

      &.out-of-statistic-with-stock:hover {
        background-color: #CF96B3;
      }

      &.out-of-statistic:hover {
        background-color: #F7DCE5;
      }

      &.no-hover {
        &:hover {
          background-color: transparent;
        }
      }

      &.shifted-row {
        position: relative;
        left: calc((10em/7)*var(--shift-amount));

        .squeeze {
          padding-right: calc(calc(5em/7) + (10em/7)*var(--shift-amount));
          /* 10px + на сколько мы сдвинули ряд */
        }

        .move {
          position: relative;
          left: calc((10em/7)*var(--shift-amount)*(-1));
        }


      }
    }


    .table-row--selected {
      background-color: var(--color-accent-100);

      &.out-of-statistic {
        background-color: #F7DCE5;
      }

      &.out-of-statistic-with-stock {
        background-color: #CF96B3;
      }
    }

    .out-of-statistic {
      background-color: #FFC0CB;

    }

    .out-of-statistic-with-stock {
      background-color: #ff5f6c;
    }

    .table-row--red {
      background-color: var(--color-danger-100);
    }

    .table-row--orange {
      background-color: var(--color-warning-100);
    }

    .table-row--highlighted {
      background-color: #ebead0;
    }

    .table-row--lastActive {
      background-color: var(--color-accent-100);
      outline: 1px solid var(--color-accent-700);
      outline-offset: -1px;
      outline-style: dashed;
      box-shadow: 0 0 0 3px var(--color-accent-100);
    }

    & td {
      position: relative;
      padding: 1em calc(5em/7);
      /*  14px 10px  */
      border: none;
      border-bottom: 1px solid var(--color-neutral-100);
      vertical-align: middle;

      min-width: 0;

      color: var(--color-primary-700);

      & .instruments-holder {
        display: flex;

        &>* {
          margin-left: calc(2em/7);

          &:first-child {
            margin-left: 0;
          }
        }
      }

      &.input-inside {
        padding-top: calc(3em/7);
        /*  6px  */
        padding-bottom: calc(3em/7);
        /*  6px  */
      }


      .label {
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }


  .u-table-column-checkbox-wrapper {
    min-width: 10px;
    width: 10px;
    box-sizing: content-box;

    & input {
      display: block;
      margin-top: 2px;
    }
  }

  .u-table-column-plus-wrapper {
    min-width: 12px;
    width: 12px;
    box-sizing: content-box;
  }
}

.rem-icon {
  width: var(--size-text-1);
  height: var(--size-text-1);
}

.u-width-100\% {
  width: 100%;
}

.u-status-icon {
  margin: auto;

  * {
    fill: var(--color-texticon-primary);
  }

  &.not-checked * {
    fill: var(--color-danger-900);
  }

  &.p-photo *,
  &.p-info * {
    fill: var(--color-warning-900);
  }

  &.checked * {
    fill: var(--color-success-700);
  }
}


@keyframes rotation {
  from {
    //-webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    //-webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.u-rotating {
  animation: rotation 2s infinite linear;
}


.u-toolbar {
  margin-top: 0.625em;
  margin-bottom: 0.625em;
  display: flex;
  flex-direction: column;

  .toolbar-row {
    display: flex;
    margin-top: 0.625em;
    align-items: center;

    &>* {
      margin-left: 0.625em;
      margin-bottom: 0;

      &:first-child {
        margin-left: 0;
      }
    }

    &:first-child {
      margin-top: 0;
    }
  }

  //& .u-search-input {
  //  margin-right: 0.625em;
  //}

  & .selector {
    min-width: 250px;

    @media screen and (max-width: 1600px) {
      min-width: 180px;
    }
  }
}

.u-flex-1-inside {
  &>* {
    flex: 1;
  }
}

.u-check-label {
  display: flex;
  align-items: center;
}

.loader-icon {
  width: 4.5em;
  height: 4.5em;
  fill: var(--color-secondary-700) !important;
}

.min-width-auto {
  min-width: auto !important;
}