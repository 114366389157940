.image-block--first-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 9px;
  margin-bottom: 9px;

  & > * {
    margin-bottom: 3px;
    margin-top: 3px;
    margin-right: 10px;
  }


  .form-type-delete {
    max-width: 300px;
  }

  .selector-choose-type {
    min-width: 160px;
  }

}

.image-block--table {
  flex: 1 1 0;
  min-width: 33%;
  max-width: 33%;

  .image-block--image {
    max-width: 100%;
    max-height: 200px;
  }
}

.image-block--tables-wrapper {
  display: flex;
  flex-wrap: wrap;


  iframe {
    max-width: 100%;
    height: auto;
  }
}

.wrapper-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.25em;

  .image-block-wrapper,
  .pictogram-block-wrapper {
    display: grid;
    gap: 0.625em;
    position: relative;
    grid-template-rows: auto auto auto 1fr;

    .control-buttons {
      position: absolute;
      right: 0.375em;
      top: 4em;
      display: flex;

      & > * {
        cursor: pointer;
        margin-right: 0.2em;
      }
    }
  }

  .pictogram-block-wrapper {
    grid-template-rows: auto 1fr auto ;
  }

  .u-droppod-info {
    white-space: nowrap;
    overflow: hidden;
  }

  .middle-block__ala-image {
    height: 100px;
  }
}